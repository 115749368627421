<template>
  <div v-if="show" class="d-flex">
    <div class="align-self-start text-left">
      <img src="/assets/img/svg/controls/coach_outline.svg" alt="" class="warning-img" />
      <p v-if="displayName" class="mt-1 mb-0">{{ botName }}</p>
    </div>
    <transition name="bounce" appear>
      <div class="box2 sb11 align-self-start ml-3">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: Boolean,
      default: true
    },
    botName: {
      type: String,
      default: 'Performio'
    }
  }
}
</script>

<style lang="scss" scoped>
.warning-img {
  height: 5.5rem;
}

/* CSS talk bubble */
.box2 {
  width: auto;
  border: 2px solid #e8e8e8;
  border-radius: 0.75rem;
  padding: 20px;
  text-align: center;
  color: #000;
  position: relative;
  font-size: 1rem;
}

/* speech bubble 11 */
.sb11:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 12px solid #e8e8e8;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -23px;
}

.sb11:after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 10px solid #fff;
  border-top: 9px solid transparent;
  border-bottom: 8px solid transparent;
  left: -17px;
  top: 21px;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  // animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
